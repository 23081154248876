var stickyHeader = true;
var transformHomepageDonationAmountImages = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = true;
var countUpStats = true;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;
// var carouselBackgroundVideoYouTubeID = 'gzwYc7OI-kU'; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;
var lualBodyClasses = ["PostCategory_christmas-appeal"];
var visualAppealsLoadMore = true;


// Footer giving
$(".Footer-GivingCTA").insertBefore('#pageFooterWrapper');

// How we help impact panel - this should be put in an if statement so just applied on this page
if ($('body').hasClass('how-we-help')) {
  $(".homefeaturecategory-homeboximpactstats").insertBefore('.carousel');
}



//$(".headerText .title").width("150px");

// document.addEventListener("DOMContentLoaded", function(event) { 
//   list = document.querySelectorAll('.title span');
//   for (var i=0; i<list.length; i++) {
//     w = list[i].offsetWidth;
//     list[i].parentNode.style.width = w+"px";
//   }
// });

// Animate stats on inner page - this is a duplication of src/fresco/js/countUpStats.js
if (typeof countUpStats !== "undefined" && countUpStats) {
  if ($("body").hasClass("how-we-help")) {
    // Set a flag so we only run it once
    let statsAnimated = false;
    // Using a setTimeout so we're not firing this every single frame of scroll
    let statsTimer;

    $(window).on("load resize scroll", function () {
      clearTimeout(statsTimer);
      statsTimer = setTimeout(function () {
        if (
          !statsAnimated &&
          $(".homefeaturecategory-homeboximpactstats").length &&
          $(".homefeaturecategory-homeboximpactstats").isInViewport()
        ) {
          $(
            ".homefeaturecategory-homeboximpactstats td:first-child > *:first-child"
          ).each(function () {
            const element = $(this);
            const originalText = element.text();
            let elementText = originalText;

            const firstChar = elementText.charAt(0);
            const lastChar = elementText.slice(-1);
            let prefix = "";
            let suffix = "";

            // Check to see if we have a countUpStatsDuration variable
            // If so, use that value for the animation swing below
            // Otherwise, let's use a default value of 3000
            const animationDuration =
              typeof countUpStatsDuration !== "undefined" &&
              countUpStatsDuration
                ? countUpStatsDuration
                : 3000;

            if (isNaN(firstChar)) {
              prefix = firstChar;
              elementText = elementText.substring(1, elementText.length);
            }

            if (isNaN(lastChar)) {
              suffix = lastChar;
              elementText = elementText.slice(0, -1);
            }

            // Strip any "," values
            elementText = elementText.replace(/,/g, "");

            // Count length of any decimal places
            const mantissaLength = elementText.split(".")[1]
              ? elementText.split(".")[1].length
              : 0;

            $({ countNum: 0 }).animate(
              {
                countNum: elementText,
              },

              {
                duration: animationDuration,
                easing: "swing",
                step: function (now) {
                  element.text(
                    prefix + parseFloat(now).toFixed(mantissaLength) + suffix
                  );
                },
                complete: function () {
                  element.text(originalText);
                },
              }
            );
          });

          // Set our flag so that we don't animate again
          statsAnimated = true;
        }
      }, 10);
    });
  }
}

// Reposition breadcrumbs when page post title over banner image

$(document).ready(function () {
  if ($('body').hasClass('page-has-banner')) {
    if ($('body').hasClass('page-has-video-banner')) {
    }
    else{
      if ($('body').hasClass('pagesPostBody') || $('body').hasClass('newsPostBody') || $('body').hasClass('eventsPostBody') || $('body').hasClass('appealsPostBody')) {
          $(".breadcrumbWrapper").insertBefore('.contentBlockWrapper:first-of-type');
      }
    }
   }
});

// Adjust width of carousel slide detail and post title to length of summary text

$(document).ready(function () {

  // Carousel slide detail
  $('.carouselSlideSummary').wrapInner('<span class="fdsa"></span>');
  $(".carouselSlide:first-of-type .carouselSlideDetail").width($(".carouselSlide:first-of-type .carouselSlideSummary .fdsa").width() +1);
  $(".carouselSlide:nth-of-type(2) .carouselSlideDetail").width($(".carouselSlide:nth-of-type(2) .carouselSlideSummary .fdsa").width() +1);
  $(".carouselSlide:nth-of-type(3) .carouselSlideDetail").width($(".carouselSlide:nth-of-type(3) .carouselSlideSummary .fdsa").width() +1);
  $(".carouselSlide:nth-of-type(4) .carouselSlideDetail").width($(".carouselSlide:nth-of-type(4) .carouselSlideSummary .fdsa").width() +1);

  // Post title
  if ($('body').hasClass('page-has-banner')) {
    if ($('body').hasClass('page-has-video-banner')) {
    }
    else{
      if ($('body').hasClass('pagesPostBody') || $('body').hasClass('newsPostBody') || $('body').hasClass('eventsPostBody') || $('body').hasClass('appealsPostBody')) {
        $('.headerText .title').wrapInner('<span class="fdsa"></span>');
        $(".headerText .title").width($(".fdsa").width() +1);
      }
    }
   }

});

// Center maps on afghanistan
$(window).bind("load", function () {
  CenterMap(new google.maps.LatLng(34.2368489,66.6082229), 6);
 });